.collapsePanel {
    &__header {
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 100;
        position: relative;

        &__fixedHeight {
            height: 3rem;
            padding: 0 0.5rem 0 1rem;
        }
        &__fixedMinHeight {
            min-height: 3rem;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
        }

        &__fixedHeight__lg {
            height: 3.5rem;
            padding: 0 0.5rem 0 1rem;
        }
    }

    &__toggleBtn {

        &.e-btn {
            background-color: transparent !important;
            border: transparent !important;
            height: 100% !important;
            // background-color: rgba(0, 0, 0, 0.15) !important;
            border-radius: 50% !important;

            &:hover {
                background-color: rgba(0, 0, 0, 0.15) !important;
            }
        }

        &__lg.e-btn {
            height: 3rem !important;
            min-height: 3rem !important;
            max-height: 3rem !important;
            width: 3rem !important;
            min-width: 3rem !important;
            max-width: 3rem !important;
            border-radius: 0% !important;
            background-color: transparent !important;
        }
    }

    &__content {
        padding: 1rem;
    }

    &__separator {
        border-bottom: 1px solid var(--stroke-neutral-base);
    }
}