.viewFilterPanel .custom-content-toolbar {
    position: absolute;
    right: 0px;
    top: 20px;
    display: inline-flex;
}

.e-tab.viewFilterPanel .e-tab-header {
    /*border-bottom: 1px solid #e7e7e7 !important;*/
    //padding-left: 15px;
    width: auto !important;
}

.e-tab.viewFilterPanel .e-tab-header .e-toolbar-items {
    padding-left: 15px;
}

.e-tab.viewFilterPanel .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
    top: 120%;
}

.e-tab.viewReportTab .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
    top: 100%;
}

.e-tab.viewFilterPanel .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 56px;
}

.e-tab.viewReportTab .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 40px;
}

.viewFilterPanel .e-toolbar .e-toolbar-item:hover {
    background: none !important;
    border-color: none !important
}

.viewFilterPanel .e-dropdown-btn {
    padding: 10px;
}

.viewFilterPanel .filterPanel {
    padding: 10px;
    height: calc(100vh - 180px);
    overflow: auto;
    margin-bottom: 0px;
}

.viewFilterPanel .filterPanel .e-group-header .e-btn-group label {
    margin: 5px !important;
}

/*sortable style start*/
.SortableItem {
    /*
    font-size: 1rem;
    padding: 15px 20px;
    list-style: none;
    background-color: #007bff;
    color: #fff;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: move;
        */
    list-style: none;
    z-index: 9999;
}


.ulcolumnchooser {
    list-style-type: none;
    padding: 10px;
    /*height: calc(100vh - 200px);*/
    overflow: auto;
    margin-bottom: 0px;
}

.ulcolumnchooser li {
    margin: 3px;
    padding: 0.5em;
    padding-left: 1em;
}

.ulcolumnchooser li .dot {
    height: 3px;
    width: 3px;
    background-color: #c8cfd3;
    border-radius: 50%;
    display: block;
    margin: 2px 1px;
}

.ulcolumnchooser li .dragIcon {
    display: flex;
    margin-right: 10px;
    margin-left: 10px;
    visibility: hidden;
    vertical-align: middle;
}

.ulcolumnchooser li:hover {
    background-color: #f2f3f4;
    cursor: pointer;
}

.ulcolumnchooser li label:hover,
.ulcolumnchooser li input[type=checkbox]:hover {
    cursor: pointer;
}

.ulcolumnchooser li:hover .dragIcon {
    visibility: visible;
    cursor: grab;
}

.ulcolumnchooser li .sortIcon {
    color: #bbb;
}

.ulcolumnchooser li .sortIcon {
    visibility: hidden;
}

.ulcolumnchooser li .sortIcon.active {
    visibility: visible;
}

.ulcolumnchooser li:hover .sortIcon {
    visibility: visible;
    cursor: pointer;
}

.ulcolumnchooser li .sortIcon .fa-icons.active {
    color: #00C851;
    /*font-weight: 900;*/
}

.ulcolumnchooser li .sortIcon.rotate {
    transform: rotate(180deg);
}

.ulcolumnchooser li .checkboxIcon {
    font-size: 18px;
    margin-right: 5px;
    /*margin-top: 5px;*/
    cursor: pointer;
}

.ulcolumnchooser li .font-normal {
    font-weight: 400;
}

.ulcolumnchooser .md-checkbox {
    margin-top: 6px !important;
}

//Toast style
.toast-container {
    position: fixed;
    right: 20px;
    top: 10px;
    bottom: initial;
    width: 350px;
    max-width: 95%;
    z-index: 2060;
    cursor: pointer;
    transition: .3s all ease;
}

.alert {
    padding: 15px;
    margin-bottom: 23px;
    border: 1px solid transparent;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
}

.alert-warning {
    /*background-color: #ffc107;*/
    background-color: #FEB303;
    opacity: 1;
    box-shadow: 0 5px 15px rgba(245, 166, 35, .5)
}

.toast-container .icon {
    font-size: 20px;
    margin-right: 10px;
}

.toast-container .alert-container {
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    width: 90%;
    cursor: pointer;
}

/*sortable style end*/

.viewFilterName .e-input-group {
    border-color: transparent !important;
    font-size: 16px;
}

.viewFilterName.headerTitle {
    display: inline !important;
}

.viewFilterName.headerTitle .form-group {
    margin-bottom: 0px;
}

.viewFilterName .e-input-group.e-input-focus {
    border: 1px solid #0078d6 !important
}

.viewFilterName .e-input-group .e-textbox {
    font-weight: 500 !important;
    font-size: 16px !important;
}

.fieldToFocus .e-input-group {
    border: none !important;
}

.rightDialog.full-height.createEditForm.viewFilterDialog.e-dialog {
    width: 450px !important;
}

.rightDialog.full-height.createEditForm.viewFilterDialog.viewFilterDialog-md.e-dialog {
    width: 550px !important;
}

.viewFilterDialog .e-dlg-body-content {
    overflow: hidden;
    display: grid;
    grid-template-rows: 65px auto;
}

.viewFilterDialog .e-rule-filter,
.viewFilterDialog .e-rule-operator,
.viewFilterDialog .e-rule-value,
.columnChooser .e-rule-filter,
.columnChooser .e-rule-operator,
.columnChooser .e-rule-value {
    display: block !important;
    width: 100% !important;
}

.viewFilterDialog .e-rule-field,
.columnChooser .e-rule-field {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 5px;
    padding-bottom: 5px;
}

.viewFilterDialog .e-rule-value-delete,
.columnChooser .e-rule-value-delete {
    order: 1;
    display: flex !important;
    align-items: center;
}

.viewFilterDialog button.e-btn.e-deletegroup,
.columnChooser button.e-btn.e-deletegroup,
.viewFilterDialog button.e-btn.e-addrulegroup,
.columnChooser button.e-btn.e-addrulegroup {
    padding: 0 !important;
}

.viewFilterDialog button.e-btn.e-deletegroup .e-btn-icon.e-icons,
.columnChooser button.e-btn.e-deletegroup .e-btn-icon.e-icons,
.viewFilterDialog button.e-btn.e-addrulegroup .e-btn-icon.e-icons,
.columnChooser button.e-btn.e-addrulegroup .e-btn-icon.e-icons {
    font-size: 0.875rem;
}

.viewFilterDialog .e-query-builder.e-device .e-group-body .e-rule-container,
.columnChooser .e-query-builder.e-device .e-group-body .e-rule-container {
    padding-bottom: 1rem;
}

.viewFilterDialog .e-query-builder.e-device .e-group-body .e-rule-container.e-vertical-mode .e-rule-delete.e-css.e-btn,
.columnChooser .e-query-builder.e-device .e-group-body .e-rule-container.e-vertical-mode .e-rule-delete.e-css.e-btn {
    margin-bottom: 0;
    padding: 0 1rem;
}

.viewFilterDialog .e-rule-filter,
.columnChooser .e-rule-filter {
    order: 1;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.viewFilterDialog .e-rule-value-delete button.e-rule-delete {
    margin-bottom: 0;
}

.viewFilterDialog .e-rule-operator,
.columnChooser .e-rule-operator {
    order: 3 !important;
}

.viewFilterDialog .e-rule-value,
.columnChooser .e-rule-value {
    order: 4 !important;
}

.viewFilterDialog .e-tab .e-tab-header .e-toolbar-item .e-text-wrap {
    height: 30px;
}

.filterPanelView .sectionTitle {
    padding: 10px 0px 0px 22px;
    font-weight: 500;
    font-size: 14px;
}

.viewFilterPanel .e-tab-header {
    //position: fixed;
    //top: 48px;
    background: #fff;
    z-index: 200;
    //margin-left: 0px
}

.viewReportTab .e-tab-header {
    top: 65px;
}

.viewFilterPanel .e-content {
    top: 45px;
}

.viewReportTab .e-content {
    top: 50px;
}

.viewFilterPanel .e-content .columnChooserPanel {
    height: calc(100vh - 180px) !important;
    overflow: auto;
}

.viewFilterPanel .titleHeader {
    position: fixed;
    right: 0;
    max-width: 100%;
    width: calc(450px - 0px);
    z-index: 200;
    background: #fff;
    padding-top: 10px;
    top: 0;
    height: 50px;
}

.rightDialog.columnChooser.full-height {
    width: 450px !important;
}

.viewFilterDialog .e-query-builder .e-group-body .e-rule-value.e-hide,
.columnChooser .e-query-builder .e-group-body .e-rule-value.e-hide {
    display: none !important;
}

.viewFilterPanel h6 {
    font-weight: 500;
}

.grouping-row .form-group {
    display: flex;
}

.grouping-row .form-group div {
    width: 65%;
    //background-color: #f4f4f4;
}

.grouping-row label {
    line-height: normal;
    width: 35%;
    margin: 10px 10px 10px 0;
    //background-color: #f4f4f4;
}

.grouping-column-row {
    margin: 0.5rem 0 !important;
    background-color: #fff;
}

.e-query-builder .e-rule-list:empty {
    display: none;
}

.e-query-builder .e-group-header .e-group-action .e-btn {
    display: inline-block;
}

@media (max-width: 576px) {
    .rightDialog.columnChooser.full-height {
        left: 10% !important;
        width: 90% !important;
    }

    .viewFilterPanel .titleHeader {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .e-tab.viewFilterPanel .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
        top: 95%;
    }
}