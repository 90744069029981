$text-secondary-color: rgba(0, 0, 0, .55);
$text-primary-color: rgba(0, 0, 0, .9);

.sectionHeader i {
    font-size: 16px;
    transition: .2s;
}

.sectionHeader i.small-icon {
    font-size: 10px;
    transition: .2s;
    font-weight: 900;
}

.sectionHeader .e-avatar i {
    font-size: unset;
}

.sectionHeader i .rotate {
    transform: rotate(90deg);
}

.sectionHeader i.rotate {
    transform: rotate(-90deg);
}

.sectionHeader i .rotate-down {
    transform: rotate(180deg);
}

.sectionHeader i.rotate-down {
    transform: rotate(-180deg);
}

.centerButtonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: 5px 10px 5px;
}

.centerButton {
    display: flex;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    background: #fff;
    border: 1px solid #e7eaec;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
}

/*Color Code for avatar & alphabets*/
.avatar-text {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f48fb1;
    //opacity: .7;
    font-weight: 400;
}

.avatar-text-circle {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1em;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f48fb1;
    opacity: .8;
}

.avatar-text-square {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1em;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 20%;
    background-color: #f48fb1;
    opacity: .8;
}

.avatar-text::after {
    width: 100%;
    height: 100%;
}

.tableDataDiv .avatar-text {
    margin-right: 10px;
}

.e-templatecell .e-avatar {
    width: 2em;
    height: 2em;
    min-width: 2em;
    min-height: 2em;
    background: #e3f2fd;
    color: #2296f4;
    margin-right: 0 !important;
}

.avatar-text.avatar-default {
    color: #676a6c;
    background-color: #f4f4f4;
}

.avatar-dashed {
    border: 1px dashed transparent;
}

.avatar-text {
    &.avatar-initial- {
        background-color: #b5d2f6;
        color: #619ee7;
        border: 0.5px solid #619ee7;
    }

    &.avatar-initial-A {
        background-color: #ade2e9;
        color: #35aebe;
        border: 0.5px solid #35aebe;
    }

    &.avatar-initial-B {
        background-color: #f2c1e2;
        color: #c869aa;
        border: 0.5px solid #c869aa;
    }

    &.avatar-initial-C {
        background-color: #f6f0a7;
        color: #d1a805;
        border: 0.5px solid #d1a805;
    }

    &.avatar-initial-D {
        background-color: #b5d2f6;
        color: #619ee7;
        border: 0.5px solid #619ee7;
    }

    &.avatar-initial-E {
        background-color: #d7d6fb;
        color: #8f8ce0;
        border: 0.5px solid #8f8ce0;
    }

    &.avatar-initial-F {
        background-color: #ffcfcf;
        color: #e86e6e;
        border: 0.5px solid #e86e6e;
    }

    &.avatar-initial-G {
        background-color: #bbf4d6;
        color: #2cbd6e;
        border: 0.5px solid #2cbd6e;
    }

    &.avatar-initial-H {
        background-color: #eaeaea;
        color: #6a6a6a;
        border: 0.5px solid #6a6a6a;
    }

    &.avatar-initial-I {
        background-color: #ade2e9;
        color: #35aebe;
        border: 0.5px solid #35aebe;
    }

    &.avatar-initial-J {
        background-color: #f2c1e2;
        color: #c869aa;
        border: 0.5px solid #c869aa;
    }

    &.avatar-initial-K {
        background-color: #f6f0a7;
        color: #d1a805;
        border: 0.5px solid #d1a805;
    }

    &.avatar-initial-L {
        background-color: #b5d2f6;
        color: #619ee7;
        border: 0.5px solid #619ee7;
    }

    &.avatar-initial-M {
        background-color: #d7d6fb;
        color: #8f8ce0;
        border: 0.5px solid #8f8ce0;
    }

    &.avatar-initial-N {
        background-color: #ffcfcf;
        color: #e86e6e;
        border: 0.5px solid #e86e6e;
    }

    &.avatar-initial-O {
        background-color: #bbf4d6;
        color: #2cbd6e;
        border: 0.5px solid #2cbd6e;
    }

    &.avatar-initial-P {
        background-color: #eaeaea;
        color: #6a6a6a;
        border: 0.5px solid #6a6a6a;
    }

    &.avatar-initial-Q {
        background-color: #ade2e9;
        color: #35aebe;
        border: 0.5px solid #35aebe;
    }

    &.avatar-initial-R {
        background-color: #f2c1e2;
        color: #c869aa;
        border: 0.5px solid #c869aa;
    }

    &.avatar-initial-S {
        background-color: #f6f0a7;
        color: #d1a805;
        border: 0.5px solid #d1a805;
    }

    &.avatar-initial-T {
        background-color: #b5d2f6;
        color: #619ee7;
        border: 0.5px solid #619ee7;
    }

    &.avatar-initial-U {
        background-color: #d7d6fb;
        color: #8f8ce0;
        border: 0.5px solid #8f8ce0;
    }

    &.avatar-initial-V {
        background-color: #ffcfcf;
        color: #e86e6e;
        border: 0.5px solid #e86e6e;
    }

    &.avatar-initial-W {
        background-color: #bbf4d6;
        color: #2cbd6e;
        border: 0.5px solid #2cbd6e;
    }

    &.avatar-initial-X {
        background-color: #eaeaea;
        color: #6a6a6a;
        border: 0.5px solid #6a6a6a;
    }

    &.avatar-initial-Y {
        background-color: #ade2e9;
        color: #35aebe;
        border: 0.5px solid #35aebe;
    }

    &.avatar-initial-Z {
        background-color: #f2c1e2;
        color: #c869aa;
        border: 0.5px solid #c869aa;
    }

    &.avatar-initial-material-A {
        background-color: #FF1744;
        color: #fff;
        border: 0.5px solid #FF1744;
    }

    &.avatar-initial-material-B {
        background-color: #F50057;
        color: #fff;
        border: 0.5px solid #F50057;
    }

    &.avatar-initial-material-C {
        background-color: #D500F9;
        color: #fff;
        border: 0.5px solid #D500F9;
    }

    &.avatar-initial-material-D {
        background-color: #651FFF;
        color: #fff;
        border: 0.5px solid #651FFF;
    }

    &.avatar-initial-material-E {
        background-color: #3D5AFE;
        color: #fff;
        border: 0.5px solid #3D5AFE;
    }

    &.avatar-initial-material-F {
        background-color: #2979FF;
        color: #fff;
        border: 0.5px solid #2979FF;
    }

    &.avatar-initial-material-G {
        background-color: #00B0FF;
        color: #fff;
        border: 0.5px solid #00B0FF;
    }

    &.avatar-initial-material-H {
        background-color: #00E5FF;
        color: #fff;
        border: 0.5px solid #00E5FF;
    }

    &.avatar-initial-material-I {
        background-color: #1DE9B6;
        color: #fff;
        border: 0.5px solid #1DE9B6;
    }

    &.avatar-initial-material-J {
        background-color: #00E676;
        color: #fff;
        border: 0.5px solid #00E676;
    }

    &.avatar-initial-material-K {
        background-color: #76FF03;
        color: #fff;
        border: 0.5px solid #76FF03;
    }

    &.avatar-initial-material-L {
        background-color: #C6FF00;
        color: #fff;
        border: 0.5px solid #C6FF00;
    }

    &.avatar-initial-material-M {
        background-color: #FFEA00;
        color: #fff;
        border: 0.5px solid #FFEA00;
    }

    &.avatar-initial-material-N {
        background-color: #FFC400;
        color: #fff;
        border: 0.5px solid #FFC400;
    }

    &.avatar-initial-material-O {
        background-color: #bbf4d6;
        color: #fff;
        border: 0.5px solid #bbf4d6;
    }

    &.avatar-initial-material-P {
        background-color: #FF9100;
        color: #fff;
        border: 0.5px solid #FF9100;
    }

    &.avatar-initial-material-Q {
        background-color: #FF3D00;
        color: #fff;
        border: 0.5px solid #FF3D00;
    }

    &.avatar-initial-material-R {
        background-color: #2962FF;
        color: #fff;
        border: 0.5px solid #2962FF;
    }

    &.avatar-initial-material-S {
        background-color: #304FFE;
        color: #fff;
        border: 0.5px solid #304FFE;
    }

    &.avatar-initial-material-T {
        background-color: #7C4DFF;
        color: #fff;
        border: 0.5px solid #7C4DFF;
    }

    &.avatar-initial-material-U {
        background-color: #536DFE;
        color: #fff;
        border: 0.5px solid #536DFE;
    }

    &.avatar-initial-material-V {
        background-color: #EC407A;
        color: #fff;
        border: 0.5px solid #EC407A;
    }

    &.avatar-initial-material-W {
        background-color: #7E57C2;
        color: #fff;
        border: 0.5px solid #7E57C2;
    }

    &.avatar-initial-material-X {
        background-color: #0097A7;
        color: #fff;
        border: 0.5px solid #0097A7;
    }

    &.avatar-initial-material-Y {
        background-color: #455A64;
        color: #fff;
        border: 0.5px solid #455A64;
    }

    &.avatar-initial-material-Z {
        background-color: #424242;
        color: #fff;
        border: 0.5px solid #424242;
    }

    &.avatar-initial-material-color {
        background-color: transparent;
        border: 1px solid #aaa;
    }

    &.avatar-initial-material-color-A {
        color: #FF1744;
        border-color: #FF1744;
    }

    &.avatar-initial-material-color-B {
        color: #F50057;
        border-color: #F50057;
    }

    &.avatar-initial-material-color-C {
        color: #D500F9;
        border-color: #D500F9;
    }

    &.avatar-initial-material-color-D {
        color: #651FFF;
        border-color: #651FFF;
    }

    &.avatar-initial-material-color-E {
        color: #3D5AFE;
        border-color: #3D5AFE;
    }

    &.avatar-initial-material-color-F {
        color: #2979FF;
        border-color: #2979FF;
    }

    &.avatar-initial-material-color-G {
        color: #00B0FF;
        border-color: #00B0FF;
    }

    &.avatar-initial-material-color-H {
        color: #00E5FF;
        border-color: #00E5FF;
    }

    &.avatar-initial-material-color-I {
        color: #1DE9B6;
        border-color: #1DE9B6;
    }

    &.avatar-initial-material-color-J {
        color: #00E676;
        border-color: #00E676;
    }

    &.avatar-initial-material-color-K {
        color: #76FF03;
        border-color: #76FF03;
    }

    &.avatar-initial-material-color-L {
        color: #C6FF00;
        border-color: #C6FF00;
    }

    &.avatar-initial-material-color-M {
        color: #c6b604;
        border-color: #c6b604;
    }

    &.avatar-initial-material-color-N {
        color: #FFC400;
        border-color: #FFC400;
    }

    &.avatar-initial-material-color-O {
        color: #72fbb4;
        border-color: #72fbb4;
    }

    &.avatar-initial-material-color-P {
        color: #FF9100;
        border-color: #FF9100;
    }

    &.avatar-initial-material-color-Q {
        color: #FF3D00;
        border-color: #FF3D00;
    }

    &.avatar-initial-material-color-R {
        color: #2962FF;
        border-color: #2962FF;
    }

    &.avatar-initial-material-color-S {
        color: #304FFE;
        border-color: #304FFE;
    }

    &.avatar-initial-material-color-T {
        color: #7C4DFF;
        border-color: #7C4DFF;
    }

    &.avatar-initial-material-color-U {
        color: #536DFE;
        border-color: #536DFE;
    }

    &.avatar-initial-material-color-V {
        color: #EC407A;
        border-color: #EC407A;
    }

    &.avatar-initial-material-color-W {
        color: #7E57C2;
        border-color: #7E57C2;
    }

    &.avatar-initial-material-color-X {
        color: #0097A7;
        border-color: #0097A7;
    }

    &.avatar-initial-material-color-Y {
        color: #455A64;
        border-color: #455A64;
    }

    &.avatar-initial-material-color-Z {
        color: #424242;
        border-color: #424242;
    }

    &.avatar-initial-material-color-A.bp-list-avatar div {
        color: #FF1744 !important;
    }

    &.avatar-initial-material-color-B.bp-list-avatar div {
        color: #F50057 !important;
    }

    &.avatar-initial-material-color-C.bp-list-avatar div {
        color: #D500F9 !important;
    }

    &.avatar-initial-material-color-D.bp-list-avatar div {
        color: #651FFF !important;
    }

    &.avatar-initial-material-color-E.bp-list-avatar div {
        color: #3D5AFE !important;
    }

    &.avatar-initial-material-color-F.bp-list-avatar div {
        color: #2979FF !important;
    }

    &.avatar-initial-material-color-G.bp-list-avatar div {
        color: #00B0FF !important;
    }

    &.avatar-initial-material-color-H.bp-list-avatar div {
        color: #00E5FF !important;
    }

    &.avatar-initial-material-color-I.bp-list-avatar div {
        color: #1DE9B6 !important;
    }

    &.avatar-initial-material-color-J.bp-list-avatar div {
        color: #00E676 !important;
    }

    &.avatar-initial-material-color-K.bp-list-avatar div {
        color: #76FF03 !important;
    }

    &.avatar-initial-material-color-L.bp-list-avatar div {
        color: #96bf05 !important;
    }

    &.avatar-initial-material-color-M.bp-list-avatar div {
        color: #c6b604 !important;
    }

    &.avatar-initial-material-color-N.bp-list-avatar div {
        color: #FFC400 !important;
    }

    &.avatar-initial-material-color-O.bp-list-avatar div {
        color: #72fbb4 !important;
    }

    &.avatar-initial-material-color-P.bp-list-avatar div {
        color: #FF9100 !important;
    }

    &.avatar-initial-material-color-Q.bp-list-avatar div {
        color: #FF3D00 !important;
    }

    &.avatar-initial-material-color-R.bp-list-avatar div {
        color: #2962FF !important;
    }

    &.avatar-initial-material-color-S.bp-list-avatar div {
        color: #304FFE !important;
    }

    &.avatar-initial-material-color-T.bp-list-avatar div {
        color: #7C4DFF !important;
    }

    &.avatar-initial-material-color-U.bp-list-avatar div {
        color: #536DFE !important;
    }

    &.avatar-initial-material-color-V.bp-list-avatar div {
        color: #EC407A !important;
    }

    &.avatar-initial-material-color-W.bp-list-avatar div {
        color: #7E57C2 !important;
    }

    &.avatar-initial-material-color-X.bp-list-avatar div {
        color: #0097A7 !important;
    }

    &.avatar-initial-material-color-Y.bp-list-avatar div {
        color: #455A64 !important;
    }

    &.avatar-initial-material-color-Z.bp-list-avatar div {
        color: #424242 !important;
    }

    &.avatar-pastel-pink {
        background-color: #ffd1dc;
    }

    &.avatar-pastel-blue {
        background-color: #a5e0ff;
    }

    &.avatar-pastel-yellow {
        background-color: #fdfd96;
    }

    &.avatar-pastel-green {
        background-color: #98fb98;
    }

    &.avatar-pastel-purple {
        background-color: #b19cd9;
    }

    &.avatar-initial-palettes-A {
        background-color: #ade2e9;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #35aebe;
    }

    &.avatar-initial-palettes-B {
        background-color: #ABDEE6;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #c869aa;
    }

    &.avatar-initial-palettes-C {
        background-color: #CBAACB;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #d1a805;
    }

    &.avatar-initial-palettes-D {
        background-color: #FFFFB5;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #619ee7;
    }

    &.avatar-initial-palettes-E {
        background-color: #FFCCB6;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #8f8ce0;
    }

    &.avatar-initial-palettes-F {
        background-color: #F3B0C3;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #e86e6e;
    }

    &.avatar-initial-palettes-G {
        background-color: #C6DBDA;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #2cbd6e;
    }

    &.avatar-initial-palettes-H {
        background-color: #FEE1E8;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #6a6a6a;
    }

    &.avatar-initial-palettes-I {
        background-color: #FED7C3;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #35aebe;
    }

    &.avatar-initial-palettes-J {
        background-color: #F6EAC2;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #c869aa;
    }

    &.avatar-initial-palettes-K {
        background-color: #ECD5E3;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #d1a805;
    }

    &.avatar-initial-palettes-L {
        background-color: #FF968A;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #619ee7;
    }

    &.avatar-initial-palettes-M {
        background-color: #FFAEA5;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #8f8ce0;
    }

    &.avatar-initial-palettes-N {
        background-color: #FFC5BF;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #e86e6e;
    }

    &.avatar-initial-palettes-O {
        background-color: #FFD8BE;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #2cbd6e;
    }

    &.avatar-initial-palettes-P {
        background-color: #FFC8A2;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #6a6a6a;
    }

    &.avatar-initial-palettes-Q {
        background-color: #D4F0F0;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #35aebe;
    }

    &.avatar-initial-palettes-R {
        background-color: #8FCACA;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #c869aa;
    }

    &.avatar-initial-palettes-S {
        background-color: #CCE2CB;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #d1a805;
    }

    &.avatar-initial-palettes-T {
        background-color: #B6CFB6;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #619ee7;
    }

    &.avatar-initial-palettes-U {
        background-color: #97C1A9;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #8f8ce0;
    }

    &.avatar-initial-palettes-V {
        background-color: #FCB9AA;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #e86e6e;
    }

    &.avatar-initial-palettes-W {
        background-color: #FFDBCC;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #2cbd6e;
    }

    &.avatar-initial-palettes-X {
        background-color: #ECEAE4;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #6a6a6a;
    }

    &.avatar-initial-palettes-Y {
        background-color: #A2E1DB;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #35aebe;
    }

    &.avatar-initial-palettes-Z {
        background-color: #55CBCD;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 700;
        //border: 0.5px solid #c869aa;
    }
}

.transparent-avatar {
    background-color: transparent !important;
    border-color: transparent !important;
}

.bg-transparent-avatar {
    background-color: transparent !important;
}

.white-avatar {
    background-color: #fff !important;
    border-color: #fff !important;
}

.profile-avatar {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
}

.avatar-sm {
    width: 1.75rem;
    min-width: 1.75rem;
    max-width: 1.75rem;
    height: 1.75rem;
    min-height: 1.75rem;
    max-height: 1.75rem;
    font-size: 0.75rem;
}

.dashed-avatar {
    border: 1px dashed !important;
}

.dashed-avatar.bp-avatar {
    border-color: #0070d2 !important;
}

.raf-avatar {
    width: 2rem;
    height: 2rem;
    border: 1px solid #999;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 1rem;

    &.circle {
        border-radius: 50%;
    }
}

.raf-avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
}

.raf-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
    color: #333;

    & img {
        height: 2rem;
        width: auto;
    }
}

.labelSecondaryText {
    font-size: 10px;
    color: #9e9e9e;
    line-height: 22px;
    font-weight: 500;
    padding-left: 8px;
    text-transform: uppercase;
}

.detailsSectionIcon {
    padding-left: 10px;
    padding-right: 5px;
}

.detailsValue {
    font-size: 0.8125rem;
    line-height: 1.8;
    color: $text-primary-color !important;
    word-break: break-all;
    font-weight: 600;
}

.formEditDiv {
    border: 1px solid #c8c8c8;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0 0.5rem;
}

.collapse .detailsLabelCol,
.collapse .detailsValueCol {
    padding-top: 6px;
    padding-bottom: 6px;
}

.collapse .detailsValueCol .editable {
    padding: 6px 0 6px 6px;
}

.profileDiv h4 .detailsValue {
    font-weight: 600;
    font-size: 20px !important;
    line-height: 1.2;
}

.page-heading .detailsField-value-div .detailsValue {
    font-size: 14px;
}

.recordInfoToggler,
.recordInfoToggler:hover {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    color: #888888;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.recordInfoToggler .fa-clock {
    font-size: 11px;
    line-height: normal;
}

/*.recordInfoToggler .e-control.e-tooltip {
    position: absolute;
    height: 90px;
    top: calc(50% - 10px);
    padding-right: 10px;
}

.recordInfoToggler .e-control.e-tooltip {
    top: calc(50% - 7px);
    width: 140px;
    right: 0;
}
*/
.summary-section-container {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 50px;
}

.key_header {
    // color: #000 !important;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--content-neutral-light);
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 6px;
    white-space: nowrap;
    // font-weight: 500;
}

.key_header label.required {
    color: red !important;
    position: relative;
}

.e-device .e-dlg-content .key_header {
    font-size: 10px;
}

.key_value {
    color: #333;
    font-size: 14px;
    white-space: nowrap;
    /*min-height: 29px;*/
}

.header-text-sm .key_value {
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.custom-content .form-group {
    margin-bottom: 0;
}

.customSummary.label-left-align .summarySection .summaryText {
    align-items: flex-start;
    padding: 0.5rem 0;
}

.customSummary.label-left-align .summarySection .summaryText .key_header {
    color: var(--content-neutral-base) !important;
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-family: 'Montserrat';
}

.customSummary.label-left-align .summarySection .summaryText .key_value {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--content-neutral-dark);
    font-weight: 500;
    // font-size: 13px;
    // font-weight: 600;
    // line-height: normal;
    vertical-align: text-top;
}

.summarySection .custom-content i {
    font-size: 14px;
}

.summarySection {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    /*background: #fff;*/
    /*padding: 0px 32px !important;*/
    flex-direction: column;
}

.summarySection i {
    font-size: 18px;
}

.summarySection .colorIcon i {
    font-size: 11px;
    line-height: normal;
}

.summaryText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    //background: #f3f6fb;
    padding: 5px 10px;
    min-width: 150px;
    //min-height: 57px;
    min-height: 64px;
    //border: 1px solid #bbdefb;
    //border: 1px solid #e7e7e3;
    border-radius: 2px;
    //border-left: 3px solid #fa5456;
    //background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-left: 3px solid #FDB482;
    width: 100%;
}

.summarySection .summaryText .link-button .detailsValue {
    cursor: pointer;
    color: #2196f3 !important;
}


.customSummary.label-left-align {
    overflow: hidden;
}

.customSummary.label-left-align .summarySection {
    border-bottom: 1px dashed #e3e3e7;
    position: relative;
    top: 1px;
}

.customSummary .summaryText {
    min-width: unset;
    min-height: unset;
    border-radius: unset;
    border: none !important;
}

.bp_summaryText {
    display: flex;
    //flex-direction: column;
    margin: 0;
    //background: #f3f6fb;
    //padding: 5px 10px;
    // min-width: 365px;
    min-height: 57px;
    //border: 1px solid #bbdefb;
    //border: 1px solid #e7e7e3;
    //border-radius: 2px;
    //border-left: 3px solid #fa5456;
    //background: #f5f5f5;
    //border: 1px solid #f0f0f0;
    //border-left: 3px solid #FDB482;
    width: 100%;
    z-index: 100;
    left: 0;
}

.row .col-md-6:last-child .bp_summaryText {
    left: unset;
    right: 0;
}

.summary-section-inline-edit-outer .summarySection {
    position: relative;
}

.summary-section-inline-edit-outer .bp_summaryText {
    position: absolute;
    bottom: calc(100% + 1rem);
    right: 0;
    left: unset;
}

.summary-section-inline-edit-outer .bp_summaryText.align-left {
    left: 0;
}

.summary-section-inline-edit-outer .bp_summaryText.align-bottom {
    top: calc(100%);
    bottom: unset;
}

.summary-section-inline-edit-outer .bp_summaryText.align-left {
    left: 0
}

.summary-section-inline-edit-outer .bp_summaryText .key_value_div {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    width: 100%;
}

.summary-section-inline-edit-outer .summaryText .key_value_div .summaryTooltip .e-Edit {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.summary-section-inline-edit-outer .bp_summaryText:not(.align-bottom) .key_value_div:after {
    bottom: -9px;
    top: unset;
    border-width: 1px;
    border-top-width: 0;
    border-right-width: 0;
    left: calc(50% - 8px);
}

.summary-section-inline-edit-outer .bp_summaryText.align-bottom .key_value_div:after {
    border-width: 1px;
    border-bottom-width: 0;
    border-left-width: 0;
    left: unset;
    right: 30px;
}

.summary-section-inline-edit-outer .bp_summaryText.align-bottom .key_value_div.center-arrow:after {
    left: 50%;
}

.summary-section-inline-edit-outer .bp_summaryText.align-left .key_value_div:after {
    left: 15px;
}

.summary-section-inline-edit-outer .bp_summaryText .key_value_div.right-caret:after {
    left: calc(100% - 24px);
}

.bp_summaryText .key_value_div {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    position: relative;
    margin-top: 0.5rem;
}

.bp_summaryText .key_value_div:after {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
    border: 1px solid #e0e0e0;
    border-bottom-width: 0px;
    border-left-width: 0px;
    background-color: #fff;
    transform: rotate(-45deg);
    top: -9px;
    left: calc(35% + 25px);
}

.custom_key_value {
    cursor: pointer;
    padding: 4px 10px;
    width: auto;
    border-radius: 2px;
    color: #333;
    font-size: 14px;
    // box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
}

.summarySection .colorIcon .custom_key_value,
.colorIcon .custom_key_value {
    box-shadow: unset;
    text-align: left;
}

.detailsValue {
    display: flex;
    align-items: center;
}

.detailsValue span,
.e-dlg-content .cardSecondaryTitle .detailsValue span {
    font-size: 9px;
    color: #c5c5c5;
}


.settingsItem {
    // padding: 0px 10px 0px 32px;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 48px;
}

.e-bigger .settingsItem .fa-sitemap.me-2 {
    margin-right: 1rem !important;
}

// .settingsItem:hover {
//     background: #f7f8f8;
//     border-color: #f7f8f8;
// }

.accordionHeader {
    cursor: pointer;
    padding: 10px;
    background: #fafafa;
}

.accordionHeader .header {
    font-size: 16px !important;
}

// .header-right-div {
//     position: absolute;
//     right: 45px;
// }

.custom_inline_editor .e-editable-action-buttons {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.e-dropdown-btn.recordInfo-dropdown-btn {
    background: transparent !important;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    //width: 220px;
    color: #333;
    //overflow: hidden;
    min-width: auto;
    width: auto;
    min-height: auto !important;
    height: auto !important;
    line-height: normal;
}

button.custom-button-sm.e-dropdown-btn.recordInfo-dropdown-btn {
    min-height: auto !important;
    height: auto;
    min-width: unset !important;
    line-height: normal;
    font-weight: 500 !important;
    color: #666;
}

// .e-popup-open.recordInfo-dropdown:hover {
//     background: transparent !important;
// }

.e-popup-open.recordInfo-tooltip.recordInfo-dropdown:hover {
    background: #fff !important;
}

.e-popup-open.recordInfo-dropdown {
    min-width: 350px;
}

.e-popup-open.recordInfo-dropdown .e-tip-content {
    width: 100%;
    padding: 0;
}

.e-popup-open.recordInfo-dropdown .e-tip-content-item {
    line-height: 36px;
    padding: 0 10px;
}

.e-popup-open.recordInfo-dropdown li,
.e-popup-open.recordInfo-dropdown .e-tip-content-item {
    border-bottom: 1px dashed #e7eaec;
    cursor: text;
    height: auto !important;
    line-height: 2.5 !important;
}

.e-dropdown-popup.recordInfo-dropdown ul .e-item:hover {
    background-color: transparent;
}

.e-popup-open.recordInfo-dropdown li:last-child,
.e-popup-open.recordInfo-dropdown .e-tip-content-item:last-child {
    border-bottom-width: 0;
}

.e-popup-open.recordInfo-dropdown span {
    font-size: 13px !important;
    color: #212529;
}

.e-popup-open.recordInfo-dropdown .dropdownmenu-label span {
    font-weight: 500;
}

@media only screen and (min-height: 800px) {
    .summary-section-inline-edit-outer .bp_summaryText {
        top: calc(100% + 0.25rem);
        bottom: unset;
        z-index: 100;
    }

    .summary-section-inline-edit-outer .bp_summaryText .key_value_div:after {
        bottom: unset;
        top: -9px;
        border-width: 1px;
        border-bottom-width: 0;
        border-left-width: 0;
        left: calc(50% - 8px);
    }
}

@media only screen and (min-width: 768px) {
    .customSummary.label-left-align .summarySection .summaryText {
        // flex-direction: row;
        padding: 0;
        padding-top: 0.5rem;
    }

    .customSummary.label-left-align .summarySection .summaryText .key_header {
        // height: 2.25rem;
        // line-height: 2.25rem;
        vertical-align: middle;
        // width: 40%;
        margin: 0;
    }

    .customSummary.label-left-align .summarySection .summaryText .key_value_div {
        height: 2.25rem;
        width: 60%;
        line-height: 2.25rem;
        vertical-align: middle;
    }

    .customSummary.label-left-align .summarySection .summaryText .key_value_div.key_html_value_div {
        height: unset;
        line-height: normal;
        padding: 0.5rem 0;
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .summarySection {
        flex-direction: column;
    }

    .summarySection .summaryCountIcon {
        margin-bottom: 10px;
    }

    .summaryText h6 {
        text-align: center;
    }

    .summaryText {
        margin-left: unset !important;
        justify-content: flex-start;
    }

    .bp_summaryText h6 {
        text-align: center;
    }

    .bp_summaryText {
        margin-left: unset !important;
        justify-content: flex-start;
    }
}

@media only screen and (min-width: 576px) {
    .bp_summaryText {
        min-width: 365px;
    }
}