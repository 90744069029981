.rafrelatedTo {
    &__Content {
        display: flex;
        align-items: center;
        line-height: 0.8125rem;
        flex-wrap: wrap;

        &__secondary {
            font-size: 0.75rem;
            font-weight: 400;
            color: #666;
            white-space: normal;
            word-break: break-all;
        }

        &__primary {
            font-size: 0.75rem;
        }

        &.swapTextwithHyperlink {
            .rafrelatedTo__Content__primary {
                font-size: 0.875rem;
            }
        }
    }
}